import { cn } from '@/utils'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './select'

export interface DayTimePickerProps {
  containerClassName?: string
  disablePast?: boolean
  error?: string
  value: string
  onChange: (value?: string) => void
}

export default function DayTimePicker({
  containerClassName,
  disablePast,
  error,
  value,
  onChange,
}: DayTimePickerProps) {
  const now = useMemo(
    () => DateTime.now().set({ second: 0, millisecond: 0 }),
    [],
  )
  const nowEod = useMemo(() => now.endOf('day'), [now])
  const currentValue = useMemo(
    () => DateTime.fromISO(value).set({ second: 0 }),
    [value],
  )
  const selectedPeriod = useMemo(
    () => (currentValue.hour >= 12 ? 'PM' : 'AM'),
    [currentValue],
  )

  const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString())
  const minutes = Array.from({ length: 60 }, (_, i) => i.toString())
  const periods = ['AM', 'PM']

  const handleTimeChange = (key: 'hour' | 'minute', value: number) => {
    const newDateTime = currentValue.set({ [key]: value })
    if (!disablePast || newDateTime >= now) {
      onChange(newDateTime.toISO() || undefined)
    }
  }

  return (
    <div>
      <div className={cn('flex flex-col gap-4', containerClassName)}>
        <Select
          value={Math.ceil(
            Math.max(currentValue.diff(nowEod, 'days').days, 0),
          ).toString()}
          onValueChange={(value) => {
            const newDate = DateTime.now()
              .plus({ day: Number(value) })
              .set({
                hour: currentValue.hour,
                minute: currentValue.minute,
                second: 0,
                millisecond: 0,
              })

            if (!disablePast || newDate >= now) {
              onChange(newDate.toISO())
            } else {
              onChange(DateTime.now().plus({ hour: 1 }).toISO())
            }
          }}
        >
          <SelectTrigger placeholder='Day'>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value='0'>Today</SelectItem>
            <SelectItem value='1'>Tomorrow</SelectItem>
            <SelectItem value='2'>In 2 days</SelectItem>
            <SelectItem value='3'>In 3 days</SelectItem>
          </SelectContent>
        </Select>

        <div className='flex gap-2'>
          <Select
            value={currentValue.toFormat('h')}
            onValueChange={(value) =>
              handleTimeChange(
                'hour',
                currentValue.hour >= 12 ? Number(value) + 12 : Number(value),
              )
            }
          >
            <SelectTrigger
              placeholder='Hour'
              className='[&_.placeholder]:left-1/2 [&_.placeholder]:-translate-x-1/2 [&_svg]:hidden'
            >
              <SelectValue />
            </SelectTrigger>
            <SelectContent align='center'>
              {hours.map((hour) => {
                const isDisabled =
                  disablePast &&
                  currentValue.startOf('day').equals(now.startOf('day')) &&
                  (selectedPeriod === 'PM' ? Number(hour) + 12 : Number(hour)) <
                    now.hour

                return (
                  <SelectItem key={hour} value={hour} disabled={isDisabled}>
                    {hour.padStart(2, '0')}
                  </SelectItem>
                )
              })}
            </SelectContent>
          </Select>

          <Select
            value={currentValue.toFormat('m')}
            onValueChange={(value) => handleTimeChange('minute', Number(value))}
          >
            <SelectTrigger
              placeholder='Minute'
              className='[&_.placeholder]:left-1/2 [&_.placeholder]:-translate-x-1/2 [&_svg]:hidden'
            >
              <SelectValue />
            </SelectTrigger>
            <SelectContent align='center'>
              {minutes.map((minute) => {
                const isDisabled =
                  disablePast &&
                  currentValue.startOf('day').equals(now.startOf('day')) &&
                  currentValue.hour === now.hour &&
                  Number(minute) < now.minute

                return (
                  <SelectItem key={minute} value={minute} disabled={isDisabled}>
                    {minute.padStart(2, '0')}
                  </SelectItem>
                )
              })}
            </SelectContent>
          </Select>

          <Select
            value={selectedPeriod}
            onValueChange={(value) => {
              let hour: number
              if (value === 'AM') {
                hour =
                  currentValue.hour >= 12
                    ? currentValue.hour - 12
                    : currentValue.hour
              } else {
                hour =
                  currentValue.hour < 12
                    ? currentValue.hour + 12
                    : currentValue.hour
              }

              handleTimeChange('hour', hour)
            }}
          >
            <SelectTrigger
              placeholder='Period'
              className='[&_.placeholder]:left-1/2 [&_.placeholder]:-translate-x-1/2 [&_svg]:hidden'
            >
              <SelectValue />
            </SelectTrigger>
            <SelectContent align='center'>
              {periods.map((period) => {
                const isDisabled =
                  disablePast &&
                  now.hour >= 12 &&
                  currentValue.startOf('day').equals(now.startOf('day')) &&
                  period === 'AM'

                return (
                  <SelectItem key={period} value={period} disabled={isDisabled}>
                    {period}
                  </SelectItem>
                )
              })}
            </SelectContent>
          </Select>
        </div>
      </div>

      {error && <p className='ml-1 mt-1 text-xs text-red-500'>{error}</p>}
    </div>
  )
}
